<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Servidores</h4>
            <div class="small text-muted">Administración de servidores</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Servidor">
              <i class="fa fa-plus"></i> Agregar
            </b-button>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">            
        <b-row>            
          <b-col sm="12">
            <b-row>                              
              <b-col sm="3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Buscar por nombre o subdominio" v-model="filters.name" v-on:keyup.enter.native="filter"></b-form-input>              
                  </b-input-group>
                </b-form-group>                                        
              </b-col>
              <b-col sm="3">
                <v-select :options="arr.select.categories" v-model="filters.categorie" placeholder="Agrupación" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>
              <b-col sm="2">
                <v-select :options="arr.filter.type" v-model="filters.type" placeholder="Tipo" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>              
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filter()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>  
            </b-row>     
          </b-col>  
        </b-row>         
      </b-card>

      <b-card header-tag="header" footer-tag="footer">  
        <b-row>
          <b-col>
            <b-table class="mb-0"
                    ref="table"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :busy="table.isBusy"     
                    v-if="table.items.length || table.isBusy">                       

                <template v-slot:cell(id)="data">
                  <b># {{data.item.id}}</b>
                </template>

                <template v-slot:cell(categorie)="data">
                  <div v-if="data.item.categorie">
                    <b>{{data.item.categorie.name}}</b>
                  </div>
                  <div v-else>
                    No Agrupado
                  </div>
                </template>

                <template v-slot:cell(name)="data">
                  <b>
                    {{data.item.name}}
                    <span v-if="data.item.show_laravel" title="Disponible en LARAVEL">
                      <b-icon icon="stack"></b-icon>
                    </span>                    
                    <span v-if="data.item.show_docker" title="Disponible en DOCKER">
                      <b-icon icon="stack"></b-icon>
                    </span>                                        
                  </b> 

                  <b-badge variant="dark" class="pull-right" v-if="data.item.type=='fullcontrol'">
                    Control Total
                  </b-badge>                  
                  <b-badge variant="info" class="pull-right" v-if="data.item.type=='readonly'">
                    Monitoreo
                  </b-badge>
                  <b-badge variant="light" class="pull-right" v-if="data.item.type=='docker'" style="color: #ffffff;background-color: #d528f1;">
                    Docker
                  </b-badge>                  
                  <b-badge variant="warning" class="pull-right" v-if="data.item.type=='backups'">
                    Backups
                  </b-badge>                                    
                  <b-badge variant="secondary" class="pull-right" v-if="data.item.type=='customer'">
                    Solo SSH
                  </b-badge>                                                      

                  <span v-if="data.item.subdomain">
                    <br>
                    <b-link target="_blank" :href="'http://'+data.item.subdomain">
                      https://{{data.item.subdomain}}
                    </b-link>                      
                  </span>
                </template>

                <template v-slot:cell(notifications)="data">                  
                  <div v-if="getParseNotification(data.item)" v-b-tooltip.hover :title="getParseNotification(data.item)">
                    <b-icon icon="bell"></b-icon>
                  </div>                           
                </template>

                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations">
                    <b-icon icon="chat-square-text"></b-icon>
                  </div>                           
                </template>

                <template v-slot:cell(status)="data">
                  <div v-if="data.item.status_install">
                    <div class="text-info font-weight-bold">
                      <b-icon icon="circle-fill" variant="info" animation="fade"></b-icon> Instalando
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="showFieldServer(data.item, 'status')">
                      <div v-if="showFieldServer(data.item, 'status') == 'Iniciado'" class="text-success font-weight-bold" :id="'server-status-' + data.item.id">
                        <b-icon icon="circle-fill" variant="success"></b-icon> {{ showFieldServer(data.item, 'status') }}
                      </div>
                      <div v-if="showFieldServer(data.item, 'status') == 'Reiniciando'" class="text-warning font-weight-bold" :id="'server-status-' + data.item.id">
                        <b-icon icon="circle-fill" variant="warning" animation="fade"></b-icon> {{ showFieldServer(data.item, 'status') }}
                      </div>                  
                      <div v-if="showFieldServer(data.item, 'status') == 'Apagado'" class="text-danger font-weight-bold" :id="'server-status-' + data.item.id">
                        <b-icon icon="circle-fill" variant="danger"></b-icon> {{ showFieldServer(data.item, 'status') }}
                      </div>

                      <b-popover :target="'server-status-' + data.item.id" 
                                  triggers="hover" 
                                  placement="top" 
                                  v-if="showFieldServer(data.item, 'status')">

                        <template #title>Estado del Servidor</template>
                        <b>Servidor:</b> {{data.item.name}}<br>
                        <b>Estado:</b> {{JSON.parse(data.item.status).status}}<br>
                        <div v-if="JSON.parse(data.item.status).error">
                          <b>Error:</b> {{JSON.parse(data.item.status).error}}<br>
                        </div>
                        <div v-else>                    
                          <b>Versión:</b> {{JSON.parse(data.item.status).version}}<br>
                          <b>Kernel:</b> {{JSON.parse(data.item.status).kernel}}<br>
                        </div>
                        <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.status).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                      </b-popover>
                    </div>
                  </div>
                </template>
                
                <template v-slot:cell(cpu)="data">
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.cpu">
                    <div v-if="showFieldServer(data.item, 'cpu') <= 0.8" class="text-success font-weight-bold" :id="'server-cpu-' + data.item.id">
                      {{ Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits: 2 }).format( showFieldServer(data.item, 'cpu')) }} Usado
                    </div>
                    <div v-if="showFieldServer(data.item, 'cpu') > 0.8 && showFieldServer(data.item, 'cpu') < 0.9" class="text-warning font-weight-bold" :id="'server-cpu-' + data.item.id">
                      {{ Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits: 2 }).format( showFieldServer(data.item, 'cpu')) }} Usado
                    </div>                  
                    <div v-if="showFieldServer(data.item, 'cpu') >= 0.9" class="text-danger font-weight-bold" :id="'server-cpu-' + data.item.id">
                      {{ Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits: 2 }).format( showFieldServer(data.item, 'cpu')) }} Usado
                    </div>
                  </div>

                  <b-popover :target="'server-cpu-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.cpu">

                    <template #title>Estado CPU</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Cantidad:</b> {{JSON.parse(data.item.cpu).total}}<br>
                    <b>Usado:</b> {{JSON.parse(data.item.cpu).usage}}<br>                    
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.cpu).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                  
                </template>

                <template v-slot:cell(disk)="data">
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.disk">
                    <div v-if="showFieldServer(data.item, 'disk') <= 0.8" class="text-success font-weight-bold" :id="'server-disk-' + data.item.id">
                      {{ Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits: 2 }).format( showFieldServer(data.item, 'disk')) }} Usado
                    </div>
                    <div v-if="showFieldServer(data.item, 'disk') > 0.8 && showFieldServer(data.item, 'disk') < 0.9" class="text-warning font-weight-bold" :id="'server-disk-' + data.item.id">
                      {{ Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits: 2 }).format( showFieldServer(data.item, 'disk')) }} Usado
                    </div>                  
                    <div v-if="showFieldServer(data.item, 'disk') >= 0.9" class="text-danger font-weight-bold" :id="'server-disk-' + data.item.id">
                      {{ Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits: 2 }).format( showFieldServer(data.item, 'disk')) }} Usado
                    </div>
                  </div>

                  <b-popover :target="'server-disk-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.disk">

                    <template #title>Estado del Disco</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Total:</b> {{JSON.parse(data.item.disk).total}}<br>
                    <b>Libre:</b> {{JSON.parse(data.item.disk).free}}<br>
                    <b>Usado:</b> {{JSON.parse(data.item.disk).usage}} - {{JSON.parse(data.item.disk).usage_percent}}<br>                    
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.disk).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                  
                </template>

                <template v-slot:cell(memory)="data">
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.memory">
                    <div v-if="showFieldServer(data.item, 'memory') <= 0.8" class="text-success font-weight-bold" :id="'server-memory-' + data.item.id">                      
                      {{ Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits: 2 }).format( showFieldServer(data.item, 'memory')) }} Usado
                    </div>
                    <div v-if="showFieldServer(data.item, 'memory') > 0.8 && showFieldServer(data.item, 'memory') < 0.9" class="text-warning font-weight-bold" :id="'server-memory-' + data.item.id">
                      {{ Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits: 2 }).format( showFieldServer(data.item, 'memory')) }} Usado
                    </div>                  
                    <div v-if="showFieldServer(data.item, 'memory') >= 0.9" class="text-danger font-weight-bold" :id="'server-memory-' + data.item.id">
                      {{ Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits: 2 }).format( showFieldServer(data.item, 'memory')) }} Usado
                    </div>                  
                  </div>

                  <b-popover :target="'server-memory-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.memory">

                    <template #title>Estado de la Memoria</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Total:</b> {{JSON.parse(data.item.memory).total}}M<br>                    
                    <b>Usado:</b> {{JSON.parse(data.item.memory).usage}}M - {{JSON.parse(data.item.memory).usage_percent}}<br>
                    <b>Libre:</b> {{JSON.parse(data.item.memory).free}}M<br>
                    <b>Disponible:</b> {{JSON.parse(data.item.memory).avalible}}M<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.memory).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>  
                </template>

                <template v-slot:cell(git)="data">         
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.git && !data.item.status_install && data.item.type!='backups'">         
                    <div v-if="showFieldServer(data.item, 'git') == 'Activo'" class="text-success font-weight-bold" :id="'server-git-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldServer(data.item, 'git') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-git-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>                  
                  </div>

                  <b-popover :target="'server-git-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.git && !data.item.status_install && data.item.type!='backups'">

                    <template #title>Estado de GIT</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Estado:</b> {{JSON.parse(data.item.git).status}}<br>
                    <b>Versión:</b> {{JSON.parse(data.item.git).version}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.git).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                  
                </template>

                <template v-slot:cell(apache)="data">         
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.apache && !data.item.status_install && data.item.type!='backups'">         
                    <div v-if="showFieldServer(data.item, 'apache') == 'Activo'" class="text-success font-weight-bold" :id="'server-apache-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldServer(data.item, 'apache') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-apache-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>                  
                  </div>

                  <b-popover :target="'server-apache-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.apache && !data.item.status_install && data.item.type!='backups'">

                    <template #title>Estado del Apache</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Estado:</b> {{JSON.parse(data.item.apache).status}}<br>
                    <b>Versión:</b> {{JSON.parse(data.item.apache).version}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.apache).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                  
                </template>

                <template v-slot:cell(composer)="data">         
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.composer && !data.item.status_install && data.item.type!='backups'">         
                    <div v-if="showFieldServer(data.item, 'composer') == 'Activo'" class="text-success font-weight-bold" :id="'server-composer-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldServer(data.item, 'composer') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-composer-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>                  
                  </div>

                  <b-popover :target="'server-composer-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.composer && !data.item.status_install && data.item.type!='backups'">

                    <template #title>Estado de Composer</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Estado:</b> {{JSON.parse(data.item.composer).status}}<br>
                    <b>Versión:</b> {{JSON.parse(data.item.composer).version}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.composer).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                  
                </template>

                <template v-slot:cell(php)="data">         
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.php && !data.item.status_install && data.item.type!='backups'">         
                    <div v-if="showFieldServer(data.item, 'php') == 'Activo'" class="text-success font-weight-bold" :id="'server-php-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldServer(data.item, 'php') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-php-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>                  
                  </div>

                  <b-popover :target="'server-php-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.php && !data.item.status_install && data.item.type!='backups'">

                    <template #title>Estado de PHP</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Estado:</b> {{JSON.parse(data.item.php).status}}<br>
                    <b>Versión:</b> {{JSON.parse(data.item.php).version}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.php).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                  
                </template>

                <template v-slot:cell(mysql)="data">
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.mysql && !data.item.status_install && data.item.type!='backups'">
                    <div v-if="showFieldServer(data.item, 'mysql') == 'Activo'" class="text-success font-weight-bold" :id="'server-mysql-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldServer(data.item, 'mysql') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-mysql-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>   
                  </div>

                  <b-popover :target="'server-mysql-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.mysql && !data.item.status_install && data.item.type!='backups'">

                    <template #title>Estado del MySql</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Estado:</b> {{JSON.parse(data.item.mysql).status}}<br>
                    <b>Versión:</b> {{JSON.parse(data.item.mysql).version}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.mysql).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                    
                </template>

                <template v-slot:cell(supervisor)="data">
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.supervisor && !data.item.status_install && data.item.type!='backups'">
                    <div v-if="showFieldServer(data.item, 'supervisor') == 'Activo'" class="text-success font-weight-bold" :id="'server-supervisor-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldServer(data.item, 'supervisor') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-supervisor-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>   
                  </div>

                  <b-popover :target="'server-supervisor-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.supervisor && !data.item.status_install && data.item.type!='backups'">

                    <template #title>Estado del Supervisor</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Estado:</b> {{JSON.parse(data.item.supervisor).status}}<br>
                    <b>Versión:</b> {{JSON.parse(data.item.supervisor).version}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.supervisor).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                   
                </template>

                <template v-slot:cell(certbot)="data">         
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.certbot && !data.item.status_install && data.item.type!='backups'">         
                    <div v-if="showFieldServer(data.item, 'certbot') == 'Activo'" class="text-success font-weight-bold" :id="'server-certbot-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldServer(data.item, 'certbot') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-certbot-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>                  
                  </div>

                  <b-popover :target="'server-certbot-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.certbot && !data.item.status_install && data.item.type!='backups'">

                    <template #title>Estado de CERTBOT</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Estado:</b> {{JSON.parse(data.item.certbot).status}}<br>
                    <b>Versión:</b> {{JSON.parse(data.item.certbot).version}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.certbot).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                  
                </template>                

                <template v-slot:cell(docker)="data">    
                  <div v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.docker && !data.item.status_install && data.item.type!='backups'">         
                    <div v-if="showFieldServer(data.item, 'docker') == 'Activo'" class="text-success font-weight-bold" :id="'server-docker-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldServer(data.item, 'docker') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-docker-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>                  
                  </div>

                  <b-popover :target="'server-docker-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="showFieldServer(data.item, 'status') && showFieldServer(data.item, 'status') == 'Iniciado' && data.item.docker && !data.item.status_install && data.item.type!='backups'">

                    <template #title>Estado de DOCKER</template>
                    <b>Servidor:</b> {{data.item.name}}<br>
                    <b>Estado:</b> {{JSON.parse(data.item.docker).status}}<br>
                    <b>Versión:</b> {{JSON.parse(data.item.docker).version}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.docker).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>            
                </template>       

                <template v-slot:cell(f_action)="data">                  
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                    
                    <b-dropdown-item @click="actionServer(data.item)" v-if="data.item.type != 'customer'">
                      <b-icon icon="terminal-fill"></b-icon> Comandos
                    </b-dropdown-item>         
                    <b-dropdown-item @click="getAccessSSH(data.item)">
                      <b-icon icon="key"></b-icon> SSH
                    </b-dropdown-item>                        
                    <b-dropdown-item @click="logServer(data.item)" v-if="data.item.type != 'customer'">
                      <b-icon icon="layout-text-sidebar-reverse"></b-icon> Log
                    </b-dropdown-item>                                  

                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>            
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filter()" />
            </nav>             
          </b-col>          
        </b-row>
      </b-card>

      <div id="command_terminal"></div>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              size="xl"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>
        <b-row>
          <b-col md="6">
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <b-row>      
                  <b-col md="8">
                    <b-form-group label="Nombre">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.name"
                                    required
                                    placeholder="Ingresar un nombre"
                                    autocomplete="off">
                      </b-form-input>
                    </b-form-group>
                  </b-col>   
                    <b-col md="4">            
                      <b-form-group label="Tipo">                    
                        <b-form-select v-model="crud.form.type" :options="arr.select.type"></b-form-select>                    
                      </b-form-group>
                    </b-col>                  
                  <b-col md="12">
                    <FindObject render="search"
                                type="instances-categories" 
                                @select-object="loadInstancesCategories($event)" 
                                :valueID="crud.form.categories_id" />           
                  </b-col>                         
                  <b-col md="12" v-if="crud.form.type=='fullcontrol'">
                    <b-form-group label="Sub Dominio">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.subdomain"
                                    required
                                    placeholder="Ingresar un subdominio"
                                    autocomplete="off"
                                    readonly 
                                    onfocus="this.removeAttribute('readonly');">
                      </b-form-input>
                    </b-form-group>
                  </b-col>   
                  
                  <b-col md="12" v-if="crud.form.type=='fullcontrol'">
                    <b-form-checkbox v-model="crud.form.show_laravel" 
                                      switch 
                                      size="sm" 
                                      class="pull-left">
                      Permite instalar instancias "LARAVEL"
                    </b-form-checkbox> 
                  </b-col>  
                  <b-col md="12" v-if="crud.form.type=='fullcontrol' || crud.form.type=='readonly'">
                    <b-form-checkbox v-model="crud.form.show_docker" 
                                      switch 
                                      size="sm" 
                                      class="pull-left">
                      Permite instalar instancias "DOCKER"
                    </b-form-checkbox> 
                  </b-col>  
                  
                  <b-col md="12" class="mt-4">
                    <b-form-group label="Observaciones">
                      <b-form-textarea                
                        v-model="crud.form.observations"
                        placeholder="Ingresar las observaciones..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>                                       
                </b-row>
              </b-tab>
              <b-tab title="SSH">
                <b-row>          
                  <b-col md="8">
                    <b-form-group label="IP Host">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.host"                            
                                    placeholder="xxx.xxx.xxx.xxx"
                                    autocomplete="off">
                      </b-form-input>
                    </b-form-group>
                  </b-col>           
                  <b-col md="4">
                    <b-form-group label="Puerto">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.form.port"                            
                                    placeholder="xxxx"
                                    autocomplete="off">
                      </b-form-input>
                    </b-form-group>
                  </b-col>         
                  <b-col md="6">
                    <b-form-group label="Usuario SSH">
                      <b-form-input type="text"
                                    size="sm"
                                    autocomplete="off"
                                    v-model="crud.form.user"                            
                                    name="ssh_user"                          
                                    placeholder="Ingresar Usuario Servidor">
                      </b-form-input>
                    </b-form-group>
                  </b-col>           
                  <b-col md="6">            
                    <b-form-group label="Contraseña SSH">
                      <b-input-group class="w-100">    
                        <b-form-input :type="password.typePasswordServer"
                                      size="sm"
                                      autocomplete="off"
                                      v-model="crud.form.password"  
                                      name="ssh_password"                          
                                      placeholder="Ingresar Contraseña Servidor">
                        </b-form-input>
                        <b-input-group-append>
                          <b-button variant="secondary" size="sm" @click="changePasswordServer()">
                            <i class="icon material-icons">{{password.iconPasswordServer}}</i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>              
                    </b-form-group>
                  </b-col>                                                                  
                </b-row>
              </b-tab>
              <b-tab title="Base de Datos" v-if="crud.form.type!='docker' && crud.form.type!='backups' && crud.form.type != 'customer'">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Usuario DB">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.database_user"                            
                                    placeholder="Ingresar Usuario Base de Datos"
                                    autocomplete="off">
                      </b-form-input>
                    </b-form-group>
                  </b-col>           
                  <b-col md="6">            
                    <b-form-group label="Contraseña DB">
                      <b-input-group class="w-100">    
                        <b-form-input :type="password.typePasswordDatabase"
                                      size="sm"
                                      v-model="crud.form.database_password"                            
                                      placeholder="Ingresar Contraseña Base de Datos"
                                      autocomplete="off">
                        </b-form-input>
                        <b-input-group-append>
                          <b-button variant="secondary" size="sm" @click="changePasswordDatabase()">
                            <i class="icon material-icons">{{password.iconPasswordDatabase}}</i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>              
                    </b-form-group>
                  </b-col>                   
                </b-row>
              </b-tab>
              <b-tab title="Notificaciones" v-if="crud.form.type!='docker' && crud.form.type!='backups' && crud.form.type != 'customer'">
                    
                <Notifications @get-notifications="getNotificationsData($event)" 
                               :role_notification="crud.form.roles_notification"
                               :staff_notification="crud.form.staff_notification"
                               :email_notification="crud.form.email_notification" />
            
              </b-tab>
            </b-tabs>
          </b-col>
          <b-col md="6">
            <b-card bg-variant="light" header="Implementación" text-variant="dark">
              <b-card-text>
                <p>
                  Podrá <b>conectarse y monitorear</b> el <b>rendimiento</b> de un servidor <b>via SSH</b> solo necesita las <b>credenciales de acceso.</b>
                </p>            
                
                <hr>                
                
                <p>
                  Podrá <b>conectarse, instalar y/o monitoriar</b> un servidor (VPS) <b>via SSH</b>, el mismo debe cumplir con los siguientes <b>requisitos</b>:
                </p>
                <ul>
                  <li>Instalación básica de <b>Ubuntu Server (versión mínima 20.04 LTS)</b></li>
                  <li>En caso de necesitar <b>acceso remoto</b> a la base de datos <b>MySQL</b>, abrir el puerto <b>3306</b> en el <b>Firewall</b></li>
                  <li>Aputar un <b>subdominio</b> a la <b>IP del servidor</b> (Tipo A => [IP] => *.s1.dominio.com)</li>
                </ul>              
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD COMMAND -->
      <b-modal v-model="modal.command.active"
              size="lg"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.command.title}}
        </div>

        <b-row v-if="selectedServer">
          <b-col md="2">                          
            <b-row>
              <b-col md="12">                          
                <b-dropdown block split text="CPU" class="mb-2 w-100">
                  <b-dropdown-item @click="getActionServer('cpu-total','Cantidad de CPU')">Cantidad</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionServer('cpu-usage','Uso del CPU (%)')">Uso (%)</b-dropdown-item>           
                </b-dropdown>
              </b-col>              
              <b-col md="12">                          
                <b-dropdown block split text="Disco" class="mb-2 w-100">
                  <b-dropdown-item @click="getActionServer('disk-total','Tamaño total del Disco (G)')">Tamaño (G)</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionServer('disk-usage-g','Uso del Disco (G)')">Usado (G)</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('disk-usage-percent','Uso del Disco (%)')">Usado (%)</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('disk-avalible','Tamaño disponible en Disco (G)')">Disponible (G)</b-dropdown-item>                  
                </b-dropdown>
              </b-col>
              <b-col md="12">                          
                <b-dropdown block split text="Memória" class="mb-2 w-100">
                  <b-dropdown-item @click="getActionServer('memory-total', 'Tamaño total de la Memoria (M)')">Tamaño (M)</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('memory-usage', 'Uso de la Memoria (M)')">Usado (M)</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('memory-usage-percent','Uso de la Memoria (%)')">Usado (%)</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('memory-free', 'Tamaño libre en la Memoria (M)')">Libre (M)</b-dropdown-item>               
                  <b-dropdown-item @click="getActionServer('memory-free', 'Tamaño disponible en la Memoria (M)')">Disponible (M)</b-dropdown-item>               
                </b-dropdown>
              </b-col>
              <b-col md="12" v-if="showServer">
                <hr class="instances-server-divide">
              </b-col>
              <b-col md="12">          
                <b-dropdown block split text="GIT" class="mb-2 w-100" v-if="showServer">
                  <b-dropdown-item @click="getActionServer('git-status', 'Estado del servicio [GIT]')">Estado</b-dropdown-item>
                  <b-dropdown-divider v-if="showFullControl"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionServer('git-install', 'Instalar [GIT]')" v-if="showFullControl">Instalar</b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-col md="12">
                <b-dropdown block split text="Apache" class="mb-2 w-100" v-if="showServer">
                  <b-dropdown-item @click="getActionServer('apache-status', 'Estado del servicio [APACHE]')">Estado</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>                  
                  <b-dropdown-item @click="getActionServer('apache-start', 'Iniciar [APACHE]')">Inciar</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('apache-stop', 'Parar [APACHE]')">Parar</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('apache-restart', 'Reiniciar [APACHE]')">Reiniciar</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('apache-reload', 'Recargar [APACHE]')">Recargar</b-dropdown-item>
                  <b-dropdown-divider v-if="showFullControl"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionServer('apache-install', 'Instalar [APACHE]')" v-if="showFullControl">Instalar</b-dropdown-item>                  
                </b-dropdown>
              </b-col>
              <b-col md="12">
                <b-dropdown block split text="Composer" class="mb-2 w-100" v-if="showServer">
                  <b-dropdown-item @click="getActionServer('composer-status', 'Estado del servicio [COMPOSER]')">Estado</b-dropdown-item>
                  <b-dropdown-divider v-if="showFullControl"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionServer('composer-install', 'Instalar [COMPOSER]')" v-if="showFullControl">Instalar</b-dropdown-item>                  
                </b-dropdown>              
              </b-col>              
              <b-col md="12">
                <b-dropdown block split text="PHP" class="mb-2 w-100" v-if="showServer">
                  <b-dropdown-item @click="getActionServer('php-status', 'Estado del servicio [PHP]')">Estado</b-dropdown-item>
                  <b-dropdown-divider v-if="showFullControl"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionServer('php-install', 'Instalar [PHP]')" v-if="showFullControl">Instalar</b-dropdown-item>                     
                </b-dropdown>              
              </b-col>              
              <b-col md="12">                          
                <b-dropdown block split text="MySql" class="mb-2 w-100" v-if="selectedServer.type!='backups'">
                  <b-dropdown-item @click="getActionServer('mysql-status', 'Estado del servicio [MYSQL]')">Estado</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="getActionServer('mysql-start', 'Iniciar [MYSQL]')">Inciar</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('mysql-stop', 'Parar [MYSQL]')">Parar</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionServer('mysql-restart', 'Reiniciar [MYSQL]')">Reiniciar</b-dropdown-item>
                  <b-dropdown-divider v-if="selectedServer.type=='fullcontrol'"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionServer('mysql-install', 'Instalar [MYSQL]')" v-if="selectedServer.type=='fullcontrol'">Instalar</b-dropdown-item>                       
                </b-dropdown>
              </b-col>
              <b-col md="12">
                <b-dropdown block split text="Supervisor" class="mb-2 w-100" v-if="showServer">
                  <b-dropdown-item @click="getActionServer('supervisor-status', 'Estado del servicio [SUPERVISOR]')">Estado</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="getActionServer('supervisor-start', 'Iniciar [SUPERVISOR]')">Inciar</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('supervisor-stop', 'Parar [SUPERVISOR]')">Parar</b-dropdown-item>                                    
                  <b-dropdown-item @click="getActionServer('supervisor-restart', 'Reiniciar [SUPERVISOR]')">Reiniciar</b-dropdown-item>
                  <b-dropdown-divider v-if="showFullControl"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionServer('supervisor-install', 'Instalar [SUPERVISOR]')" v-if="showFullControl">Instalar</b-dropdown-item>                                         
                </b-dropdown>
              </b-col>        
              <b-col md="12">
                <b-dropdown block split text="Certbot" class="mb-2 w-100" v-if="showServer">
                  <b-dropdown-item @click="getActionServer('certbot-status', 'Estado del servicio [CERTBOT]')">Estado</b-dropdown-item>
                  <b-dropdown-divider v-if="showFullControl"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionServer('certbot-install', 'Instalar [CERTBOT]')" v-if="showFullControl">Instalar</b-dropdown-item>                                                           
                </b-dropdown>                                          
              </b-col>
              <b-col md="12">
                <b-dropdown block split text="Docker" class="mb-2 w-100" v-if="showServer">
                  <b-dropdown-item @click="getActionServer('docker-status', 'Estado del servicio [DOCKER]')">Estado</b-dropdown-item>
                  <b-dropdown-divider v-if="showServer"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionServer('docker-install', 'Instalar [DOCKER]')" v-if="showServer">Instalar</b-dropdown-item>                                                           
                </b-dropdown>                                          
              </b-col>              
              <b-col md="12">
                <hr class="instances-server-divide">
              </b-col>              
              <b-col md="12">                          
                <b-dropdown block split text="Servidor" class="mb-2 w-100">
                  <b-dropdown-item @click="getActionServer('server-status', 'Estado del servidor')">Estado</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionServer('server-fullcheck', 'Checkeo general')">Checkeo General</b-dropdown-item>
                  <b-dropdown-item @click="getActionServer('server-crontab', 'Tareas Programadas (Crontab)')">Tareas Programadas (Crontab)</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>                  
                  <b-dropdown-item @click="getActionServer('server-restart', 'Reiniciar servidor')">Reiniciar</b-dropdown-item>
                  <b-dropdown-divider v-if="showFullControl"></b-dropdown-divider>                  
                  <b-dropdown-item @click="getActionServer('server-install', 'Instalar servidor')" v-if="showFullControl">Instalar</b-dropdown-item>
                </b-dropdown>
              </b-col>        
            </b-row>
          </b-col>
          <b-col md="10">
            <b-row>
              <b-col md="8">
                <h4>{{this.modal.command.subtitle}}</h4>
              </b-col>
              <b-col md="4" class="ssh-console-footer">
                <span v-if="selectedServer.status_install">
                  <span class="text-info font-weight-bold">
                    <b-icon icon="circle-fill" variant="info" animation="fade"></b-icon> Instalando
                  </span>
                </span>                  
                <b-button variant="danger" size="sm" class="pull-right" title="Refrescar" @click="refreshServer()" v-if="!SSHLoader">
                  <b-icon icon="arrow-clockwise" />
                </b-button>
              </b-col>
            </b-row>
            <div v-if="!SSHLoader" class="ssh-console-server" v-html="SSHConsole"></div>
            <div v-else class="text-left mt-5">
              <b-spinner label="Spinning" type="grow"></b-spinner>
            </div>
          </b-col>
        </b-row>

        <div slot="modal-footer" class="ssh-console-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeActionServer()">Cerrar</b-button>                  
        </div>
      </b-modal>     

      <!-- CRUD LOG -->
      <b-modal v-model="modal.log.active"
              size="xl"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.log.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-table class="mb-0"                    
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tableLog.items"
                    :fields="tableLog.fields"                    
                    :current-page="tableLog.currentPage"
                    :per-page="tableLog.perPage"
                    :busy="tableLog.isBusy"     
                    v-if="tableLog.items.length || tableLog.isBusy">    

                <template v-slot:cell(date)="data">
                  {{moment(data.item.date).format('DD/MM/YYYY HH:mm:ss')}}
                </template>
                
                <template v-slot:cell(reference)="data">
                  <b>{{data.item.reference}}</b>
                </template>

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>                               
          </b-col>
          <b-col md="12" class="mt-3">
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(tableLog.items)"
                            :per-page="tableLog.perPage"
                            v-model="tableLog.currentPage" />
            </nav>
          </b-col>             
        </b-row>

        <div slot="modal-footer" class="ssh-console-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeLogServer()">Cerrar</b-button>                  
        </div>
      </b-modal>          
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import FindObject from '@/components/inc/find/findObject'  
  import Storage from '@/handler/storageLocal'
  import Notifications from '@/components/inc/notifications'

  export default {
    components: {
      FindObject,    
      Notifications,  
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.INSTANCIAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudInstancesServer',
          elements: {}
        },         
        table : {
          items: [],
          fields: [
            {key: 'categorie', label: 'Categoría', class: 'align-middle'},                                    
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle'},            
            {key: 'name', label: 'Nombre', class: 'align-middle'},                      
            {key: 'notifications', label: '', class: 'align-middle'},                       
            {key: 'observations', label: '', class: 'align-middle'},                                   
            {key: 'status', label: 'Estado', class: 'align-middle text-center'},                       
            {key: 'cpu', label: 'CPU', class: 'align-middle text-center'},      
            {key: 'disk', label: 'Disco', class: 'align-middle text-center'},      
            {key: 'memory', label: 'Memoria', class: 'align-middle text-center'},                  
            {key: 'git', label: 'GIT', class: 'align-middle text-center'},      
            {key: 'apache', label: 'Apache', class: 'align-middle text-center'},
            {key: 'composer', label: 'Composer', class: 'align-middle text-center'},            
            {key: 'php', label: 'PHP', class: 'align-middle text-center'},            
            {key: 'mysql', label: 'MySql', class: 'align-middle text-center'},      
            {key: 'supervisor', label: 'Supervisor', class: 'align-middle text-center'},      
            {key: 'certbot', label: 'Certbot', class: 'align-middle text-center'},            
            {key: 'docker', label: 'Docker', class: 'align-middle text-center'},     
            {key: 'f_action', label:'', class: 'align-middle text-center'},
          ],          
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,                
        },
        tableLog: {
          items: [],
          fields: [
            {key: 'date', label: 'Fecha-Hora', class: 'align-middle'},          
            {key: 'reference', label: 'Referencia', class: 'align-middle'},          
            {key: 'title', label: 'Acción', class: 'align-middle'},          
            {key: 'description', label: 'Descripción', class: 'align-middle'},          
          ],
          currentPage: 1,
          perPage: 20,   
          isBusy: false,       
        },
        crud: {
          form: {
            id: 0,
            name: '',  
            host: '',
            port: '',
            user: '',
            password: '',
            database_user: '',
            database_password: '',
            categories_id: 0,
            categories: null,
            subdomain: '',
            observations: '',
            type: 'readonly',
            show_laravel: false,
            show_docker: false,
            roles_notification: [],            
            staff_notification: [],            
            email_notification: [],            
          },          
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          command: {
            active: false,
            title: '',
            subtitle: 'Terminal',
          }, 
          log: {
            active: false,
            title: '',            
          },                    
        },
        filters: {
          forceStatusServer: false,
          name: '', 
          categorie: null,
          type: '',
        },
        arr: {    
          roles: [],
          staff: [],      
          select: {
            categories: [],
            type: [
              { value: 'fullcontrol', text: 'Control Total' },
              { value: 'readonly', text: 'Monitoreo' },              
              { value: 'docker', text: 'Docker' },
              { value: 'backups', text: 'Backups' },
              { value: 'customer', text: 'Solo SSH' },
            ], 
          },
          filter: {
            type: [
              { code: 'fullcontrol', label: 'Control Total' },
              { code: 'readonly', label: 'Monitoreo' },              
              { code: 'docker', label: 'Docker' },
              { code: 'backups', label: 'Backups' },
              { code: 'customer', label: 'Solo SSH' },
            ],                        
          }
        },
        password: {
          typePasswordServer: 'password',
          iconPasswordServer: 'visibility',
          typePasswordDatabase: 'password',
          iconPasswordDatabase: 'visibility',          
        },  
        selectedServer: null,           
        SSHConsole: '',
        SSHLoader: false,
        server: {
          restart: false,
          searchInterval: null,         
        }       
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.filterLoadCategories()
      this.restoreFilterStorage() 

      this.getStaff()
      this.getRoles()
      
      this.filter()      
    },
    watch: {        
      'server.restart': function (newQuestion, oldQuestion) {
        if(newQuestion) {
          this.server.interval = setInterval(() => {   
            this.filters.forceStatusServer = true
            this.filter()
          },10000)   
        } else {
          this.filters.forceStatusServer = false
          clearInterval(this.server.interval)     
        }
      }
    },    
    computed: {
      showFullControl() {
        return this.selectedServer.type=='fullcontrol'
      },
      showServer() {
        return this.selectedServer.type=='readonly' || this.selectedServer.type=='fullcontrol' || this.selectedServer.type=='docker'
      },
      showBackups() {
        return this.selectedServer.type=='backups'
      },
      showSSH() {
        return this.selectedServer.type=='customer'
      }
    }, 
    methods: {
      // render
      getRowCount (items) {
        return items.length
      },

      // crud
      filter() {
        this.saveFilterStorage()

        this.table.isBusy = true

        var result = serviceAPI.filtrarServidor(this.filters, this.table.currentPage)

        result.then((response) => {
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }      

          var obj = null
          var status = false
          data.data.forEach(element => {
            obj = JSON.parse(element.status)

            if(obj.status == 'Reiniciando') {
              status = true
            }
          });

          if(status) {
            this.server.restart = true
          } else {
            this.server.restart = false
            this.filters.forceStatusServer = false
            clearInterval(this.server.interval)               
          }

          this.table.isBusy = false          
        })
        .catch(error => {
          this.table.isBusy = false
          //this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = '' 
        this.crud.form.host = '' 
        this.crud.form.port = '' 
        this.crud.form.user = '' 
        this.crud.form.password = '' 
        this.crud.form.database_user = '' 
        this.crud.form.database_password = '' 
        this.crud.form.categories_id = 0
        this.crud.form.categories = null
        this.crud.form.subdomain  = ''
        this.crud.form.observations = ''
        this.crud.form.type = 'readonly'
        this.crud.form.show_laravel = false
        this.crud.form.show_docker = false
        this.crud.form.roles_notification = []        
        this.crud.form.staff_notification = []        
        this.crud.form.email_notification = []        

        this.modal.form.title = "Nuevo Servidor"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name     
        this.crud.form.host = item.host
        this.crud.form.port = item.port
        this.crud.form.user = item.user
        this.crud.form.password = item.password
        this.crud.form.database_user = item.database_user
        this.crud.form.database_password = item.database_password
        this.crud.form.categories_id = item.categories_id
        this.crud.form.categories = item.categories
        this.crud.form.subdomain  = item.subdomain
        this.crud.form.observations = item.observations
        this.crud.form.type = item.type
        this.crud.form.show_laravel = item.show_laravel
        this.crud.form.show_docker = item.show_docker

        if(item.roles_notification) {
          this.crud.form.roles_notification = JSON.parse(item.roles_notification)
        } else {
          this.crud.form.roles_notification = item.roles_notification
        }

        if(item.staff_notification) {
          this.crud.form.staff_notification = JSON.parse(item.staff_notification)
        } else {
          this.crud.form.staff_notification = item.staff_notification
        }

        if(item.email_notification) {
          this.crud.form.email_notification = JSON.parse(item.email_notification)
        } else {
          this.crud.form.email_notification = item.email_notification
        }

        this.modal.form.title = "Editar Servidor"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el servidor (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Servidor',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarServidor(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filter()
              this.$awn.success("Servidor eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        var status = true        
        if(this.crud.form.email_notification && this.crud.form.email_notification.length) {
          let arrEmail = this.crud.form.email_notification
          arrEmail.forEach(element => {
            if(element) {
              var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
              if (!expreg.test(element)) {                          
                status = false
              }       
            }            
          });
        }
        if(!status) {
          this.$awn.alert("Unos de los emails posee un formato inválido")          
          loader.hide()
          return false
        }

        if (this.crud.form.id) {
          var result = serviceAPI.editarServidor(this.crud.form);
        } else {
          var result = serviceAPI.agregarServidor(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.filter()
          this.$awn.success("Cambios guardado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      // data notifications
      getNotificationsData(data) {        
        this.crud.form.email_notification = data.email_notification

        var role = []
        if(data.role_notification) {
          data.role_notification.forEach(element => {
            role.push(element.code)
          });
        }
        this.crud.form.roles_notification = role

        var staff = []
        if(data.staff_notification) {
          data.staff_notification.forEach(element => {
            staff.push(element.code)
          });
        }
        this.crud.form.staff_notification = staff              
      },
      getStaff() {
        serviceAPI.obtenerStaff().then(response => {
          var data = response.data    

          data.forEach(element => {              
            this.arr.staff.push(element)
          });             
        })
      },  
      getRoles() {
        serviceAPI.obtenerRoles().then(response => {
          var data = response.data    

          data.forEach(element => {              
            this.arr.roles.push(element)
          });                
        })
      },        
      getNotificationsName(type, id) {
        var name = ''
        if(type=='roles') {
          this.arr.roles.forEach(element => {            
            if(element.id == id) {
              name = element.name
            }
          });
        }
        if(type=='staff') {
          this.arr.staff.forEach(element => {            
            if(element.id == id) {
              name = element.name
            }
          });
        }        
        return name
      },
      getParseNotification(item) {  
        var resultRoles = ''
        var roles = item.roles_notification
        if(roles) {          
          resultRoles = 'ROLES: '
          JSON.parse(roles).forEach(element => {
            resultRoles = resultRoles + this.getNotificationsName('roles',element) + ", "
          });          
        }
        if(resultRoles) {
          resultRoles = resultRoles.substring(0,resultRoles.length - 2) + ' | '
        } 

        var resultStaff = ''
        var staff = item.staff_notification        
        if(staff) {        
          resultStaff = 'STAFF: '  
          JSON.parse(staff).forEach(element => {
            resultStaff = resultStaff + this.getNotificationsName('staff',element) + ", "
          });          
        }
        if(resultStaff) {
          resultStaff = resultStaff.substring(0,resultStaff.length - 2) + ' | '
        } 

        var resultEmails = ''
        var emails = item.email_notification        
        if(emails) {
          if(emails != '[]') {        
            resultEmails = 'EMAILS: '  
            JSON.parse(emails).forEach(element => {
              resultEmails = resultEmails + element + ", "
            });          
          }        
          if(resultEmails) {
            resultEmails = resultEmails.substring(0,resultEmails.length - 2) + ' | '
          } 
        }

        var result = resultRoles + resultStaff + resultEmails
        if(result) {
          result = result.substring(0,result.length - 3)
        }
        
        return result
      },       

      // select
      loadInstancesCategories(object) {
        if(object){
          this.crud.form.categories = object
          this.crud.form.categories_id = object.id                 
        } else {
          this.crud.form.categories = null
          this.crud.form.categories_id = 0
        }               
      },

      // filters 
      filterLoadCategories() {      
        var result = serviceAPI.obtenerCategories()        
        result.then((response) => {
          var data = response.data    

          data.forEach(element => {              
            this.arr.select.categories.push({code: element.id, label: element.name})
          });                                
        })   
      },           

      // visible password
      changePasswordServer() {
        if (this.password.typePasswordServer === 'password') {
          this.password.typePasswordServer = 'text'
          this.password.iconPasswordServer = 'visibility_off'
        } else {
          this.password.typePasswordServer = 'password'
          this.password.iconPasswordServer  = 'visibility'
        }
      },            
      changePasswordDatabase() {
        if (this.password.typePasswordDatabase === 'password') {
          this.password.typePasswordDatabase = 'text'
          this.password.iconPasswordDatabase = 'visibility_off'
        } else {
          this.password.typePasswordDatabase = 'password'
          this.password.iconPasswordDatabase  = 'visibility'
        }
      },
      
      // fields server table 
      showFieldServer(item, type) {
        var data = null
        var obj = null

        if(type == 'cpu') {
          obj = JSON.parse(item.cpu)

          if(obj && obj.usage) {
            data = parseFloat(obj.usage.replace('%','')) / 100
          }
        }

        if(type == 'status') {
          obj = JSON.parse(item.status)

          if(obj && obj.status) {
            data = obj.status
          }            
        }

        if(type == 'disk') {
          obj = JSON.parse(item.disk)

          if(obj && obj.usage_percent) {
            data = parseFloat(obj.usage_percent.replace('%','')) / 100
          }
        }
        
        if(type == 'memory') {
          obj = JSON.parse(item.memory)   
          
          if(obj && obj.usage_percent) {            
            data = parseFloat(obj.usage_percent.replace('%','')) / 100
          }
        }

        if(type == 'git') {          
          obj = JSON.parse(item.git)

          if(obj && obj.status) {
            data = obj.status
          }
        }

        if(type == 'apache') {          
          obj = JSON.parse(item.apache)

          if(obj && obj.status) {
            data = obj.status
          }
        }
        
        if(type == 'composer') {          
          obj = JSON.parse(item.composer)

          if(obj && obj.status) {
            data = obj.status
          }
        }
        
        if(type == 'php') {          
          obj = JSON.parse(item.php)

          if(obj && obj.status) {
            data = obj.status
          }
        }

        if(type == 'mysql') {
          obj = JSON.parse(item.mysql)

          if(obj && obj.status) {
            data = obj.status
          }
        }

        if(type == 'supervisor') {
          obj = JSON.parse(item.supervisor)

          if(obj && obj.status) {
            data = obj.status
          }
        }
        
        if(type == 'certbot') {          
          obj = JSON.parse(item.certbot)

          if(obj && obj.status) {
            data = obj.status
          }
        }

        if(type == 'docker') {          
          obj = JSON.parse(item.docker)

          if(obj && obj.status) {
            data = obj.status
          }
        }

        return data
      },

      // log
      logServer(item) {
        this.table.isBusy = true

        var result = serviceAPI.filtrarServidorLog({
          instances_server_id: item.id,
        })
        result.then((response) => {
          var data = response.data
          this.table.isBusy = false

          this.tableLog.items = data
        })
        .catch(error => {
          this.table.isBusy = false
          //this.$awn.alert(Error.showError(error))
        });   

        this.modal.log.title = "Log Servidor: [" + item.name + "]"        
        this.modal.log.active = true
      },
      closeLogServer() {
        this.modal.log.active=false
      },

      getAccessSSH(item) {        
        var command = document.getElementById("command_terminal");
        var commandSSH = document.createElement("div");
        commandSSH.setAttribute("id", 'command_ssh');
        commandSSH.textContent = 'ssh ' + item.user + '@' + item.host + " -p " + item.port;   
        command.appendChild(commandSSH);
        
        var codigoACopiar = document.getElementById('command_ssh')        
        var seleccion = document.createRange(); 
        seleccion.selectNodeContents(codigoACopiar);

        window.getSelection().removeAllRanges();
        window.getSelection().addRange(seleccion); 

        try {
          var res = document.execCommand('copy');
          if (res){
            this.$awn.success("Accesos via SSH copiado al portapapeles");
            setTimeout(()=> {
              this.$awn.info("Contraseña: " + item.password);
            },1500)            
          } else {
            this.$awn.alert("Error al copiar acceso");
          }    
          
          commandSSH.remove()
        }
        catch(ex) {
          commandSSH.remove()            
        }
        window.getSelection().removeRange(seleccion);        
      },

      // action
      actionServer(item) {
        this.selectedServer = item
        this.SSHConsole = ''

        this.modal.command.title = "Comandos Servidor: [" + item.name + "]"
        this.modal.command.subtitle = "Terminal"

        this.modal.command.active = true
      },
      closeActionServer() {
        this.modal.command.active=false
        this.filter()
      },
      refreshServer() {
        var result = serviceAPI.mostrarServidor(this.selectedServer.id);
        
        result.then((response) => {
          var data = response.data

          this.selectedServer = data

          this.$awn.success("Servidor refrescado");
        }) 
      },
 
      getActionServer(reference, titulo) {

        // titulo de referencia
        this.modal.command.subtitle = titulo

        // si reinicia el servidor, lo maneja otro evento
          if(reference == 'server-restart') {
            this.restartServer(reference)
            return false
          }
        // fin

        // si instala servicios en el servidor, lo maneja otro evento
          if(reference == 'server-install') {
            this.installServer(reference, 'instalar todos los SERVICIOS', 'Instalar SERVIDOR')
            return false
          }
          if(reference == 'git-install') {
            this.installServer(reference, 'instalar el servicio GIT', 'Instalar GIT')
            return false
          }
          if(reference == 'apache-install') {
            this.installServer(reference, 'instalar el servicio APACHE', 'Instalar APACHE')
            return false          
          }
          if(reference == 'composer-install') {
            this.installServer(reference, 'instalar el servicio COMPOSER', 'Instalar COMPOSER')
            return false
          }
          if(reference == 'php-install') {
            this.installServer(reference, 'instalar el servicio PHP', 'Instalar PHP')
            return false
          }
          if(reference == 'mysql-install') {
            this.installServer(reference, 'instalar el servicio MYSQL', 'Instalar MYSQL')
            return false
          }
          if(reference == 'supervisor-install') {
            this.installServer(reference, 'instalar el servicio SUPERVISOR', 'Instalar SUPERVISOR')
            return false
          }
          if(reference == 'certbot-install') {
            this.installServer(reference, 'instalar el servicio CERTBOT', 'Instalar CERTBOT')
            return false
          }
          if(reference == 'docker-install') {
            this.installServer(reference, 'instalar el servicio DOCKER', 'Instalar DOCKER')
            return false
          }          
        // fin
          
        // comandos
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } else {
          item = this.selectedServer
        }

        this.SSHLoader = true
        var result = serviceAPI.commandActionServidor({
          id: item.id, 
          command: reference
        });

        result.then((response) => {                    
          var data = response.data

          this.SSHConsole = data
          this.filter()
          this.SSHLoader = false
        })
        .catch(error => {          
          this.SSHConsole = 'Inactivo'
          this.SSHLoader = false
          this.$awn.alert(Error.showError(error));
        })
      },
      restartServer(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } else {
          item = this.selectedServer
        }
                
        this.$bvModal.msgBoxConfirm('¿Desea reiniciar servidor (' + item.name + ')?', {
          title: 'Reiniciar Servidor',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'warning',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'warning',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionServidor({
              id: item.id, 
              command: reference
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Inactivo'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },    
      installServer(reference, titleLegend, titleModal) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } else {
          item = this.selectedServer
        }
                
        this.$bvModal.msgBoxConfirm('¿Desea ' + titleLegend + ' en VPS: ' + item.name.toUpperCase() + '?', {
          title: titleModal,
          size: 'md',
          buttonSize: 'md',
          okVariant: 'info',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'info',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionServidor({
              id: item.id, 
              command: reference
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en la instalación'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },  
      
      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_view_instances_server', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_view_instances_server')) {
          this.filters = JSON.parse(Storage.getValue('filter_view_instances_server'))
        }         
      },       
    }    
  }
</script>
<style>
  .ssh-console-server {
    background-color: black;
    background-image: radial-gradient(
      rgba(0, 150, 0, 0.75), black 120%
    );
    height: 410px;
    color: white;
    font: 13px Inconsolata, monospace;    
    text-shadow: 0 0 5px #C8C8C8;
    padding: 10px;
    white-space: pre;
    overflow: auto;
  }
  .ssh-console-server::after {
    content: "";
    /*position: absolute;*/
    top: 0;
    left: 0;
    width: 100vw;
    height: 410px;
    background: repeating-linear-gradient(
      0deg,
      rgba(black, 0.15),
      rgba(black, 0.15) 1px,
      transparent 1px,
      transparent 2px
    );
    padding: 10px;
  }  
  .ssh-console-server::selection {
    background: #0080FF;
    text-shadow: none;
  }  
  .ssh-console-footer {
    z-index: 9999;
  }
  .instance-server-divide {
    margin-top: 1px;
    margin-bottom: 10px;    
  }
</style>